import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Layout from "../components/layout";
import Seo from "../components/seo";

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      contentfulMassageEnCoaching(contentful_id: { eq: "7KM2CYyd11WtAzehBOtM0t" }) {
        id
        body {
          json
        }
        title
        massageEnCoachingImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
      }
    }
  `);

  const { body, title, massageEnCoachingImage } = data.contentfulMassageEnCoaching;

  return (
    <Layout>
      <Seo
        title="Massage en Coaching"
        description="Een veilige omgeving voor liefdevolle massage en coaching"
      />
      {/*<BackgroundImage
          fluid={massageEnCoachingImage.fluid}
          style={{
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          opacity: 0.1
        }}>*/}
      <Grid
        container
        spacing={0}
        alignItems="flex-start"
        justify="space-between"
        style={{ marginBottom: "4rem" }}
      >
         <Grid item xs={12}>
          <div className="textStyleTitleCoaching">
            {title}
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={0} style={{ width: "100%", height: "50vh" }}>
            <Img
              fluid={massageEnCoachingImage.fluid}
              fadeIn
              alt={massageEnCoachingImage.description}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ objectFit: 'contain' }}
            ></Img>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography component="div" color="textPrimary" align="center">
            {documentToReactComponents(body.json)}
          </Typography>
        </Grid>
        <Grid item xs={false} md={1}></Grid>
      </Grid>
      {/*</BackgroundImage>*/}
    </Layout>
  );
}
